/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker');

import '@azure/storage-blob';

const { BlobServiceClient } = require("@azure/storage-blob");

const uploadFiles = async () => {
    try {
        enable_loading_spinner();
        console.log("Uploading files...");
        const promises = [];
        let upd_data = JSON.parse($('#custom_data').val() || '[]');
        for (const file of document.getElementById('file').files) {
            const uploadName = new Date().getTime() + file.name;
            let sasUrl = new URL(get_azure_sas_uri());
            sasUrl.pathname = '/webdm/' + uploadName;
            const location = sasUrl.toString();

            const blockBlobClient = getContainerClient().getBlockBlobClient(uploadName);
            promises.push(blockBlobClient.uploadBrowserData(file));
            $('</p>').text(file.name).appendTo('#files');
            upd_data.push({
                metadata: {
                    size: file.size,
                    location: location,
                    filename: file.name.match(/[^\/\\]+$/)[0],
                    mime_type: file.type
                }
            });
        }
        await Promise.all(promises);

        $('#custom_data').val(JSON.stringify(upd_data));

        disable_loading_spinner();
        enable_submit_button();

        console.log("Done.");
    }
    catch (error) {
        console.log(error.message);
    }
};

function getContainerClient() {
    const serviceClient = new BlobServiceClient(get_azure_sas_uri());
    return serviceClient.getContainerClient('webdm');
}

function open_upload() {
    if (is_azure_uploads()) {
        open_upload_azure();
    } else{
        open_upload_s3();
    }
}

window.addEventListener('DOMContentLoaded', function() {
    if (is_dm_page()) {
        document.getElementById("open-upload").addEventListener("click", open_upload);
        initUploadsAzure();
    }
});

function initUploadsAzure() {
    if (is_azure_uploads()) {
        document.getElementById('file').addEventListener("change", uploadFiles);
    }
}
